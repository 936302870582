window.onload = function fadeOutEffect() {


    var fadeTarget = document.getElementById("se-pre-con");


    if (fadeTarget !== null) { 

        var fadeEffect = setInterval(function() {
            if (!fadeTarget.style.opacity) {
                fadeTarget.style.opacity = 1;
            }
            if (fadeTarget.style.opacity > 0) {
                fadeTarget.style.opacity -= 0.2;
            } else {
                fadeTarget.style.display = 'none';
                clearInterval(fadeEffect);
            }
        }, 30);
    }
    


}
